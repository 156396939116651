.custom-scrollbar {
  position: relative;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #ffd000 transparent; /* Scrollbar color for Firefox */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent; /* Transparent track */
  margin: 4px; /* Adds margin between div and scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #ffd000; /* Scrollbar thumb color */
  border-radius: 12px; /* Rounded scrollbar thumb */
  border: 5px solid transparent; /* Padding for space effect */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #14223d; /* Slightly darker yellow for hover */
}
