@tailwind base;
@tailwind components;
@tailwind utilities;


.portfolio .limit-table td {
  @apply py-1 px-4; 
}

.portfolio .investments thead th {
  @apply py-6 px-6  ; 
}

.portfolio .investments tbody {
  @apply mt-12; 
}

.portfolio .investments .empty {
  @apply py-8 px-8; 
}
