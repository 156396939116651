.property-details .gradient-overlay {
  position: absolute;
  bottom: 0%;
  left: 0;
  width: 100%;
  background-size: cover;
  height: 100%;
  background: linear-gradient(
    182deg,
    rgba(20, 34, 61, 0) 1.6%,
    rgba(20, 34, 61, 0) 46.26%,
    #14223d 98.69%
  );
  border-radius: 0 0 20px 20px;
}

@keyframes pulse {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}

.property-details .pulse-loader {
  animation: pulse 1.5s infinite ease-in-out;
  background-color: #e0e0e0;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.property-details ::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

.property-details ::-webkit-scrollbar-thumb {
  background-color: #ffd000; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Roundness of the scrollbar thumb */
  border: 3px solid #f6f7f9; /* Adds some padding around the thumb */
}

.property-details ::-webkit-scrollbar-thumb:hover {
  background-color: #e2af17; /* Color of the thumb when hovered */
}

.property-details ::-webkit-scrollbar-track {
  background-color: #f3f4f6; /* Color of the scrollbar track */
  border-radius: 6px; /* Roundness of the scrollbar track */
}

/* Hide the default arrows in number input fields for all browsers */
.property-details [type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.property-details input[type="number"]::-webkit-outer-spin-button,
.property-details input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.tab-details-text {
  padding-top: 20px;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 177.778% */
  letter-spacing: -0.36px;
  max-height: auto;
  overflow: hidden;
  position: relative;
  transition: max-height 0.3s ease;
  white-space: pre-line;
}

.tab-details-text ul {
  padding-left: 20px;
}
.tab-details-text li {
  list-style-type: disc;
}

.read-more-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;
  color: #111827;
  margin-top: 8px;
}

.tab-details-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  transition: max-height 0.7s ease;
}

.tab-details-text.collapsed {
  max-height: 185px;
}

.tab-details-text.expanded {
  max-height: 10000px; /* Set a large enough value to accommodate the expanded content */
}

.map > div {
  border-radius: 20px;
}

.marker {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: #14223D;
  border-width: 4px;
  border-color: #ffcf00;
  border-style: solid;
  cursor: pointer;
  padding: 10px;
  transition: all 0.3s ease;
}

.marker img {
  width: 24px;
}

.marker .text {
  margin-top: 16px;
  text-align: center;
  background: rgb(20 34 60);
  padding: 4px 10px;
  width: 100px;
  left: -25px;
  position: absolute;
  opacity: 0.8;
  color: white;
}

.marker:hover {
  transform: scale(1.2);
}

.marker:hover .text {
  background-color: #14223D;
  border-color: #FFD000;
  color: #ffffff;
}

.custom-marker {
  position: relative;
  display: inline-block;
  text-align: center;
}

.ellipse {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none; /* Prevent ellipses from interfering with hover/clicks */
}

.ellipse-1 {
  width: 80px;
  height: 80px;
  z-index: 0;
}

.ellipse-2 {
  width: 115px;
  height: 115px;
  z-index: 0;
}

.ellipse-3 {
  width: 140px;
  height: 140px;
  z-index: 0;
}

.marker-icon {
  z-index: 20;
  width: 32px; /* Adjust size as necessary */
  height: 35px; /* Adjust size as necessary */
  isolation: isolate;
}

.marker-label {
  margin-top: 8px;
  background: #14223D;
  color: #fff;
  padding: 12px 14px;
  border-radius: 7px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 85px; /* Adjust based on marker size */
  white-space: nowrap;
  color: var(--Secondary-Color, #F8F8F8);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.28px;
}

.custom-marker:hover .marker-label {
  background-color: #FFD000;
  color: #14223D;
}
/* Hide scrollbar by default */
.property-details .hidden-scrollbar::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Optional: Only if you want the background to be transparent */
}

/* Show scrollbar on hover */
.property-details .hidden-scrollbar:hover::-webkit-scrollbar {
  background: #ccc; /* Optional: background color of the scrollbar */
}

/* Custom scrollbar styling */
.property-details .hidden-scrollbar::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded edges on the scrollbar thumb */
}

.property-details .hidden-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color when hovering over the scrollbar thumb */
}
