.btn-bold {
  font-weight: 700;
}

.btn-wide {
  padding: 10px 40px;
}

.btn:hover {
  background: #FFD000;
  color: #000000;
  transition: all 0.3s ease;
}

.btn-yellow {
  background: #FFD000;
  color: #000000;
  transition: all 0.3s ease;
}

.btn-yellow:hover {
  background: #14223D;
  color: #FFD000;
}

.btn-white {
  background: #ffffff;
  color: #14223D;
  transition: all 0.3s ease;
  border: 1px solid #14223D;
}

.btn-white:hover {
  background: #14223D;
  color: #ffffff;
}

.btn-square {
  border-radius: 8px;
}

.btn-full-width{
  width: 100%;
  margin-bottom: 2rem;
}

.btn-photo {
  z-index: 6;
  position: absolute;
  bottom: 10%;
  left: calc(100% / 2 - 136px);
  border: 3px solid #ffd000;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  opacity: 0.8;
}

.btn-photo-cancel {
  position: absolute;
  bottom: 100px;
  right: 20px;
  width: 70px;
  height: 70px;
  padding: 3px 10px;
  border-width: 4px;
  font-size: 35px;
}