.range-slider {
    margin-block: 50px;
}

.range-slider h2 {
    text-align: left;
    font-size: 1.2rem !important;
    margin-bottom: 10px;
    margin-top: 0 !important;
}

.range-labels {
    display: flex;
    justify-content: space-between;

    >span {
        text-align: center;
        color: white;
        font-size: 14px;
        font-weight: 600;

        >div {
            color: #838DA1;
            font-size: 12px;
        }
    }
}

@media screen and (max-width: 768px) {
    .range-slider {
        margin-block: 20px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .range-slider h2 {
        font-size: 0.9rem !important;
    }

    .range-labels {
        >span {
            font-size: 12px;
        }
    }
    
}