
@tailwind base;
@tailwind components;
@tailwind utilities;
.property .breadcrumb .divider {
  @apply px-2; /* Applied padding */
}

.property .images {
  @apply flex flex-row flex-nowrap gap-10; /* Applied display, direction, and gap */
}

.text-preserve-line-breaks {
  white-space: pre-line;
}



.property .images img {
  @apply w-full h-auto; /* Applied width and height */
}

.property .images .big-image {
  @apply flex-1; /* Applied flex */
}

.property .images .small-images {
  @apply grid grid-cols-2 gap-10 flex-1 max-h-96 overflow-auto; /* Applied grid layout, gap, flex, max height, and overflow */
}

.property .documents > div {
  @apply cursor-pointer transition duration-300; /* Applied cursor and transition */
}

.property .documents > div:hover {
  @apply bg-light-grey; /* Applied background color on hover */
}

.property .add-to-cart {
  @apply sticky top-20 self-start; /* Applied sticky positioning and alignment */
}

@media (max-width: 1024px) {
  .property .images {
    @apply flex-col; 
  }
  
  .property .images .big-image,
  .property .images .small-images {
    @apply flex-1; 
  }

  .property .images .small-images {
    @apply grid-cols-1; 
  }
}

/*Skeleton Styles */
@media (max-width: 1024px) {
  .hidden-mobile-skeleton {
    display: none !important;
  }

  .big-image-skeleton {
    width: 100% !important;
  }

  .detail-cards-skeleton {
    width: 100% !important;
  }
}
