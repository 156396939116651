@tailwind base;
@tailwind components;
@tailwind utilities;


.cart .cart-item .cart-item-details h3 {
  @apply mb-4; 
}

.cart .cart-item .cart-item-image img {
  @apply max-w-32 rounded-lg; 
}

.cart .cart-item .cart-item-details .cart-stats {
  @apply flex gap-8; 
}

.cart .cart-item .cart-item-details .cart-stats p {
  @apply mb-2; 
}

.cart .cart-item .cart-item-value {
  @apply h-8; 
}

.cart .cart-item .cart-item-value .input-button {
  @apply py-1 px-3.5 leading-none cursor-pointer transition ease-in duration-100 select-none; 
}

.cart .cart-item .cart-item-value .input-button:hover {
  @apply bg-light-grey; 
}

.cart .cart-item .cart-item-value .input-button:active {
  @apply border-blue-500; 
}

.cart .cart-item .cart-item-value .input-value-prepend {
  @apply py-1 pl-2.5; 
}

.cart .cart-item .cart-item-value .input-group input {
  @apply py-1 px-2.5 max-w-32; 
}
