

@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@100;300;400;500;600;700;800;900&display=swap');


p, a, h1, h2, h3, h4, h5, h6, div, span, strong, table {
  margin: 0;
  padding: 0;
  font-family: "Plus Jakarta Sans", --apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.2px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


  .p{
    @apply font-sans  tracking-normal leading-relaxed;
  }
  .a{
  @apply font-sans text-deep-blue leading-none tracking-normal no-underline;
  }
  .h1{
    @apply font-sans text-deep-blue tracking-normal text-3xl mb-8 font-bold leading-normal;
  }
  .h2{
    @apply font-sans text-deep-blue  tracking-normal text-2xl mb-4 mt-8 font-bold leading-normal;
  }
  .h3{
    @apply font-sans text-deep-blue tracking-normal font-bold leading-normal;
  }
  .h4{
    @apply font-sans text-deep-blue  tracking-normal font-bold leading-normal;
  }
  .h5{
    @apply font-sans text-deep-blue tracking-normal font-bold leading-normal;
  }
  .h6{
    @apply font-sans text-deep-blue tracking-normal font-bold leading-normal;
  }
  .div{
    @apply font-sans leading-none tracking-normal;
  }
  .span{
    @apply font-sans text-deep-blue leading-none tracking-normal;
  }
  .strong{
    @apply font-sans text-deep-blue leading-none tracking-normal;
  }

  .table {
      @apply font-sans text-deep-blue leading-none tracking-normal;
    }

  .text-grey {
    @apply text-gray-800; /* Adjust color to your preference */
  }

  .text-yellow {
    @apply text-yellow-400; /* Adjust color to your preference */
  }

  .text-dark-yellow {
    @apply text-yellow-600; /* Adjust color to your preference */
  }

  .text-blue {
    @apply text-deep-blue; /* Use the same blue variable as in root styles */
  }

  .text-red {
    @apply text-red-500; /* Adjust color to your preference */
  }
  .text-xl {
    @apply text-4xl;
  }

  .text-light {
    @apply font-light;
  }

  .text-bold {
    @apply font-semibold;
  }

