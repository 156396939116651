*, *::before, ::after {
  --blue: #14223D;
  --light-yellow: #F5CA48AA;
  --yellow: #F5CA48;
  --dark-yellow: #e2b019;
  --white: #FFFFFF;
  --lighter-grey: #E2E8F0;
  --light-grey: #F6F7F9;
  --lightest-grey: #D3D9E5;
  --dark-grey: #A3ABBA;
  --darker-grey: #5E6473;
  --red: #ff0000;
  --background: #f6f7f9;
  --box-shadow: 0px 0.25px 1px 0px #0000000A,0px 0.6px 2.5px 0px #00000007,0px 1.15px 4.75px 0px #00000006,0px 2px 8.5px 0px #00000005,0px 3.75px 15.85px 0px #00000004,0px 9px 38px 0px #00000003;
}

*, *::before, ::after {
  border-color: var(--lighter-grey);
}

body {
  padding: 0;
  margin: 0;
  background-color: var(--background);
}
/* 
.hr {
  border: 0;
  height: 2px ;
  background-color: var(--lighter-grey);
  margin: 2rem 0;
} */

#root {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.content {
  max-width: 80%;
  width: 100%;
  padding: 2rem;
}

@media (max-width: 600px) {
  .content {
    max-width: 100%;
    padding: 1rem;
  }
  .card{
    margin: 0 !important;
    width: 100% !important;
  }
}

.content.full {
  max-width: 100%;
}

.card {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background-color: var(--white);
  border-style: solid;
  border-width: 1.5px;
  border-radius: 8px;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  line-height: 1.2;
  border-radius: 8px;
  font-weight: 600;
  transition: all .2s ease-in;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px 32px;
  height: 0.75rem;
  min-width: 4.5rem;
  font-size: 1rem;
  background: var(--blue);
  color: var(--white);
  border-style: solid;
  cursor: pointer;
}

.btn:disabled {
  background: var(--light-grey);
  color: var(--dark-grey);
  cursor: not-allowed;
}

.btn .lni {
  margin-right: 10px;
  font-size: 1.25rem;
}

.btn.btn-secondary {
  background: var(--light-grey);
  color: var(--blue);
  border: 1px solid var(--blue);
}



.btn:hover {
  background: var(--dark-grey);
}

.btn.btn-full {
  width: calc(100% - 64px);
}

.tabs {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
  background-color: var(--white);
  padding: 0.375rem 0.5rem;
  border-radius: 9999px;
  box-shadow: var(--box-shadow);
  max-width: 800px !important;
}

.tabs .tab-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  font-weight: 600;
  transition: all .2s ease-in;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 32px;
  padding-right: 32px;
  height: 2rem;
  font-size: 0.875rem;
  border-color: var(--dark-grey);
  background-color: var(--white);
  color: var(--blue);
  border-radius: 9999px;
  border-width: 0px;
  flex: 1 1 auto;
  cursor: pointer;
}

.tabs .tab-item:hover {
  background-color: var(--blue);
  border-color: var(--blue);
  color: var(--white);
}

.tabs .tab-item.selected {
  background-color: var(--light-yellow);
}


.tag {
  border-radius: 4px;
  border: 1px solid #E2E8F0;
  background-color: var(--light-grey);
  padding: 2px 5px;
  font-size: 0.75rem;
  width: fit-content;
}

.progress .baseline {
  width: 100%;
  height: 8px;
  background-color: var(--lightest-grey);
  border-radius: 9999px;
}

.progress .current {
  height: 8px;
  background-color: var(--dark-yellow);
  border-radius: 9999px;
  position: relative;
  top: -8px;
}

.global-error {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #ac1100;
  padding: 10px;
  text-align: center;
  color: white;
  font-weight: bold;
  box-shadow: var(--box-shadow);
}
@media screen and (max-width: 1024px) {
  .tabs {
    flex-direction: column;
    border-radius: 8px;
    width: 100%;
  }
  .tabs .tab-item.selected {
    background-color: var(--light-yellow);
    width: 100%;
  }
  
}
@media screen and (max-width: 1100px) {
  .tabs  {
    width: 100%;
  }
  
}