.vault .transactions thead th {
  padding: 1rem 0.5rem 1rem 0.5rem;
}

.vault .transactions tbody {
  margin-top: 50px;
}

.vault .transactions .empty {
  padding: 2rem;
}

.vault .deposit-methods .deposit-method .deposit-method-content p {
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
}

.vault table tbody td {
  padding: 1rem 0.5rem;
}

.selectable-payment-method {
  cursor: pointer;
}

.selectable-payment-method:hover {
  border-color: #000000;
}

.selectable-payment-method.selected {
  background-color: var(--yellow);
}