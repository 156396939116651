.container {
    width: 100%;
    margin-bottom: 1.5rem;
    max-width: 48rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
}

.container.horizontal {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container.vertical {
    display: flex;
    flex-direction: column;
}

.move-right {
    width: 120%;
    padding-left: 15%;
}

.line-background {
    /* position: absolute; */
    background-color: #E9EAEE
}

.line-background.horizontal {
    top: 50%;
    left: 0;
    width: 100%;
    height: 6px;
    transform: translateY(-50%);
    position: relative;
    top:28px;
}

.line-background.vertical {
    top: 0;
    left: 50%;
    width: 6px;
    height: 100%;
    transform: translateX(-50%);
}

.progress-line {
    background-color: #FFD000;
    transition: all 0.3s ease-in-out;
}

.progress-line.horizontal {
    height: 6px;
    transform: translateY(-50%);
    position: relative;
    top:22px;
}

.progress-line.vertical {
    left: 50%;
    width: 4px;
    transform: translateX(-50%);
}

.step-container {
    display: flex;
    flex-direction: row;
    position: relative; /* Ensure this container is the reference point for absolute children */
    justify-content: space-between; /* Distributes steps evenly */

}

.step-item {
    flex: 0 1 auto; /* Prevent shrinking but allow growing if needed */
    position: relative;
    display: flex;
    text-align: left;
    flex-direction: column;
    flex: 1 !important; /* This makes each step occupy equal width */
}

.step-circle {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E9EAEE;
    transition: color 0.3s ease-in-out;
    font-weight: 800;
    color: #BEBFC4;
}

.step-circle.active {
    background-color: #FFD000;
    color: #14223D;
}

.step-label {
    white-space: pre-line; /* Allows line breaks to be respected */
    font-size: 0.85rem;
    margin-top: 0.5rem;
    color: #B4B6BB;
    font-weight: 600;
    max-width: 80%;
}

.step-label.active {
    color: #14223D;
}

.navigation-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.divider {
    height: 1px;
    background-color: #ddd;
    color:#5a3ca8;
    width: 100%;
    margin: 2rem 0;
}

.content-container {
    text-align: center;
    margin-bottom: 1rem;
}

.content-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
}

.content-description {
    font-size: 1.2rem;
    font-weight: bold;
    color: #717E96;
    margin: 0;
}

.button {
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
}

.button.primary {
    background-color: #6b46c1;
    color: white;
}

.button.primary:hover {
    background-color: #5a3ca8;
}

.button.disabled {
    background-color: #e2e8f0;
    color: #a0aec0;
}

/* @media (min-width: 1024px) {
    .step-container {
        gap: 3rem;
    }
} */
/* Tablet Devices */
@media (max-width: 1024px) {
  .container {
    width: 100%;
  }
  
  .account .stepContent{
    margin:1rem;
    width: 70%;
   }

  .line-background.horizontal {
    height: 4px;
    top:20px;
  }

  .progress-line.horizontal {
    height: 4px;
    top:16px;
  }

  .step-item {
    margin-bottom: 1rem;
  }

  .step-circle {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 0.9rem;
  }

  .step-label {
    font-size: 0.8rem;
    letter-spacing: -0.2px;
  }

  .content-title {
    font-size: 1.2rem;
    letter-spacing: -0.2px;
  }

  .content-description {
    font-size: 1rem;
    padding: 0!important;
    letter-spacing: -0.2px;
  }
}

/* Mobile Devices */
@media (max-width: 768px) {
  .container {
    width: 100%;
    padding: 0 0.5rem;
  }
   .account .stepContent{
    margin:0.8rem;
    padding:0.8rem;
    width: 100%;
   }

  .line-background.horizontal {
    height: 3px;
    top:16px;
  }

  .progress-line.horizontal {
    height: 3px;
    top:13px;
  }

  .step-circle {
    width: 1.2rem;
    height: 1.2rem;
    font-size: 0.8rem;
  }

  .step-label {
    font-size: 0.7rem;
    letter-spacing: -0.2px;
  }

  .content-title {
    font-size: 1.1rem;
    letter-spacing: -0.2px;
  }

  .content-description {
    font-size: 0.9rem;
    padding: 0!important;
    letter-spacing: -0.2px;
  }

  .navigation-buttons {
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }

  .button {
    width: 100%;
    padding: 0.75rem;
    font-size: 0.9rem;
  }
}
