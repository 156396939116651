@tailwind base;
@tailwind components;
@tailwind utilities;


form .form-group {
  @apply flex flex-col gap-2; /* Applied flex utilities */
}

form .form-group label {
  @apply font-semibold; /* Applied font weight */
}

form .form-group input {
  @apply rounded-md border border-gray-300 px-8 py-4 text-lg text-gray-700; /* Applied border, padding, font size, and color */
}
