@tailwind base;
@tailwind components;
@tailwind utilities;


@layer utilities {
  .animate-fadeInUp {
    animation: fadeInUp 0.3s ease-in-out;
  }
}


.modal-overlay {
  @apply fixed inset-0 bg-black bg-opacity-50 z-40; /* Applied position, dimensions, background color, and z-index */
}

.modal {
  @apply fixed bg-white rounded-lg p-8 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  animate-fadeInUp; /* Applied position, background color, border radius, padding, centering, z-index, and animation */
}

.modal .modal-header {
  @apply flex justify-between pb-2 border-b mb-4; /* Applied display, spacing, border style, and margin */
}

.modal .modal-header .modal-title {
  @apply font-semibold; /* Applied font weight */
}

.modal .modal-header .modal-close {
  @apply py-1 px-2 cursor-pointer rounded-md; /* Applied padding, cursor, and border radius */
}

.modal .modal-header .modal-close:hover {
  @apply border; /* Applied border style on hover */
}

.modal .error-message {
  @apply mb-4 text-red-500; /* Applied margin and text color */
}

.scroll-hidden {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.scroll-hidden::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
@media (max-width: 1024px) {
  .modal {
    @apply w-4/5; /* Applied width for smaller screens */
    @apply p-4; /* Applied padding for smaller screens */
  }
}
