@tailwind base;
@tailwind components;
@tailwind utilities;


.properties .gallery {
  @apply grid grid-cols-1 gap-8  lg:grid-cols-3; /* Applied grid utilities with responsive variant */
  place-items: stretch start; /* Applied place-items */
}

.properties .gallery .item {
  @apply w-full max-w-xs md:max-w-sm ; /* Adjusted transition duration and timing */
}
.properties .gallery .item {
  width: 100%;
  max-width: 500px;
  min-height: auto;
  transition: all 0.1s ease-in;
  cursor: pointer;
}

.properties .gallery .item:hover {
  margin-top: -10px;
}

.properties .gallery .item .highlight {
  @apply h-8; /* Applied height */
}

.properties .gallery .item .image-slides > img {
  @apply w-full h-48; /* Applied width and height */
  
}

.properties .gallery .tags {
  @apply mb-2; /* Applied margin-bottom */
}

.properties .gallery-content {
  @apply p-4; /* Applied padding */
}

.properties .pricing {
  @apply my-2; /* Applied margin-top and margin-bottom */
}

.properties .financials {
  @apply py-1 px-2; /* Applied padding */
}
.dropdown {
  padding: 10px 20px;
  border-radius: 100px;
  background: #14223D;
  color: #ffffff;
  font-weight: bold;
  border: 5px solid white;
  margin-right: 10px;
  appearance: none;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAMAAAAM7l6QAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAGlQTFRFAAAA/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////ButdgAAACN0Uk5TAC+su5UN2f+N6qVX/O8u+/A3WPE4YP30PmE/QGL1Zv46cSODITcUAAAAhUlEQVR4nO3QMQuDQAwF4PdQBNHhECoIIv7/H+WmOAjSDiKWioUqF3PaoZvQNwW+JBeO+Br++XcmVR/5FBw4WzgKDmfN3iA4+uyyCfiQp0X+KDVkv788mcR8zA57xo33rTRsoRnZa3XjNXB5c+PXOGLk09vT2ar61IKtfddllGSFc9a5Li8wSBofQrHPnwAAAABJRU5ErkJggg==);
  background-position: 100%;
  background-repeat: no-repeat;
  padding-right: 35px;
}


@media screen and (min-width: 640px) {
  .properties .gallery {
    @apply justify-start; /* Applied justify-items */
  }
}

@media screen and (max-width: 1440px) {
  .properties .gallery{
    @apply grid-cols-1 lg:grid-cols-2; /* Adjusted grid columns */
    justify-items: center;;
  }
  
}




/* .properties .gallery {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(1, minmax(0px, 1fr));
  -webkit-box-align: stretch;
  place-items: stretch center;
}

.properties .gallery .item {
  width: 100%;
  max-width: 390px;
  min-height: auto;
  transition: all 0.1s ease-in;
  cursor: pointer;
}

.properties .gallery .item:hover {
  margin-top: -10px;
}

.properties .gallery .item .highlight {
  height: 32px;
}

.properties .gallery .item .image-slides > img {
  width: 100%;
  height: 232px;
}

.properties .gallery .tags {
  margin-bottom: 0.5rem;
}

.properties .gallery-content {
  padding: 1rem;
}

.properties .pricing {
  margin: 0.5rem 0;
}

.properties .financials {
  padding: 4px 8px;
}

@media screen and (min-width: 640px) {
  .properties .gallery {
    justify-items: flex-start;
    grid-template-columns: repeat(3, minmax(0px, 1fr));
  }
} */