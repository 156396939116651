@tailwind base;
@tailwind components;
@tailwind utilities;

.sidebar-container {
  @apply z-20;
}

.sidebar {
  @apply fixed transition-all duration-200 ease-in-out;
  min-width: 270px;
}

.content {
  @apply ml-[270px];
}

.property-content {
  @apply ml-[275px];
  @apply mr-2;
}

.sidebar .logo {
  @apply mb-2.5 mr-2.5;
}

.sidebar .logo > svg {
  @apply max-w-[150px] mt-4 mb-2.5 mr-2;
}

.sidebar .top-menu{
  @apply px-2.5;
}
.sidebar .bottom-menu {
  @apply px-2.5;
}

.sidebar .top-menu {
  @apply pb-2.5 mb-2.5;
}

.sidebar .bottom-menu {
  @apply justify-between;
}

.sidebar .top-menu > a{
  @apply py-2.5 my-1 transition-all duration-200 ease-in;
}

.sidebar .bottom-menu > a {
  @apply py-2.5 my-1 transition-all duration-200 ease-in;
}

.sidebar .top-menu > a.selected{
  @apply bg-[var(--light-yellow)] text-[var(--blue)] font-medium rounded-md;
}

.sidebar .bottom-menu > a.selected{
  @apply bg-[var(--light-yellow)] text-[var(--blue)] font-medium rounded-md;
}

.sidebar .top-menu > a:hover{
  @apply bg-[var(--light-yellow)] text-[var(--blue)] font-medium rounded-md;
}

.sidebar .bottom-menu > a:hover {
  @apply bg-[var(--light-yellow)] text-[var(--blue)] font-medium rounded-md;
}

.sidebar .top-menu > a .lni{
  @apply mr-2.5 ml-1 ;
}
.sidebar .bottom-menu > a .lni {
  @apply mr-2.5 ml-1;
}

.mobile-menu {
  @apply hidden absolute top-[1.2rem] right-4 text-2xl cursor-pointer select-none py-[1rem] px-[1rem] pt-[0.8rem] transition-all duration-200 ease-in;
}

.sidebar-container.show-mobile .mobile-menu {
  @apply bg-white rounded-lg border-solid shadow-[var(--box-shadow)];
}

/* Responsive and State Styles */

@media (max-width: 1024px) {
  .content {
    @apply ml-0;
  }

  .property-content {
    @apply mx-20;
  }

  .mobile-menu {
    @apply block;
  }
  .sidebar {
    @apply -ml-[100vw];
  }
  .sidebar-container.show-mobile .sidebar {
    @apply ml-0;
  }
}

@media (max-width: 610px) {
  .property-content{
    @apply mx-6;
  }
}
