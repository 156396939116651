@tailwind base;
@tailwind components;
@tailwind utilities;

.flex-2 {
  flex: 2;
}
.flex-1 {
  flex: 1;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}




@media only screen and (max-width : 768px) {

  .m-hidden {
    display: none !important;
  }

  .m-flex-row {
    flex-direction: row !important;
  }
  
  .m-flex-column {
    flex-direction: column !important;
  }

  .m-flex-column-reverse {
    flex-direction: column-reverse !important;
  }

  .m-align-center {
    align-items: center !important;
  }

  .m-justify-center {
    justify-content: center !important;
  }

  .m-space-between {
    justify-content: space-between !important;
  }

  .m-space-around {
    justify-content: space-around !important;
  }

  .m-width-full {
    width: 100% !important;
  }

}