@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .max-w-640 {
    max-width: 1200px;
  }
}

.account {
  @apply max-w-640 m-auto flex items-center ;

}

@media screen and (max-width: 1024px) {
  .account form {
    width: 80%;
  }
}



/*

@layer components {
  .max-w-200 {
    max-width: 200px;
  }
  .max-w-700 {
    max-width: 700px;
  }
}

.account .logo {
  @apply max-w-200 mx-auto text-center;
}


.account h1 {
  @apply text-5xl font-bold tracking-wide mt-24; 
}

.account h3 {
  @apply text-xl font-semibold mb-5 text-center tracking-normal;
}

.account p {
  @apply text-base font-normal tracking-tight max-w-screen-md mx-auto text-center -mt-2 mb-10;
}

.account form {
  @apply bg-white rounded-lg shadow-md mx-auto max-w-700 p-8 w-1/2; 
}

.account form .info {
  @apply mb-5 px-5 py-4 border rounded-lg bg-light-grey; 
}

.account form .error {
  @apply mb-5 px-5 py-4 border rounded-lg bg-light-grey text-red-600; 
}

.account form .form-group {
  @apply p-4 flex flex-col gap-2; 
}

.account form .form-group label {
  @apply text-base font-bold mb-2 tracking-normal -mt-2; 
}

.account form .form-group input {
  @apply box-border p-4 border border-gray-300 rounded w-full text-base; 
}

*/

.account h2 {
  margin-top: 100px;
  font-size: 42px;
  font-weight: bold;
  letter-spacing: 1px;
}

.account>p {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  max-width: 770px;
  text-wrap: pretty;
  margin: 0 auto;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;
}

.account .stepContent {
  padding: 35px;
  max-width: 700px;
  width: 50%;
  background: #FFFFFF;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 0 auto;
}

.account form h3 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
  letter-spacing: 0px;
}

.account form input,
.account form select {
  box-sizing: border-box;
  padding: 15px;
  border: 1px solid #D3D9E5;
  border-radius: 8px;
}

.account form label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  letter-spacing: 0px;
  margin-top: 10px;
}

.password-container {
  position: relative;
  width: 100%;
}

.password-input {
  padding-right: 56px !important;
  width: 100%;
}

.password-toggle-button {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
}

.password-toggle-button .icon {
  width: 24px;
  height: 24px;
  color: #9ca3af;
}

.account form .hint {
  font-size: 0.85rem;
  font-style: italic;
  color: #4d4d4d;
}

.account form .info-message {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #dfdfdf;
  border-radius: 6px;
  background-color: #f9f9f9;
}

.account form .error-message {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #dfdfdf;
  border-radius: 6px;
  background-color: #f9f9f9;
  color: #ff0000;
  font-weight: 500;
}

.account form .actions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 1rem;
}

.name-container {
    display: flex;
    gap: 1rem; /* Adds space between the two input divs */
    flex-wrap: wrap; /* Ensures items don’t overflow on smaller screens */
}

.name-container > div {
    flex: 1; /* Makes both divs take equal width */
    min-width: 0; /* Prevents overflow by allowing divs to shrink as needed */
}

.name-container input {
    width: 100%; /* Ensures input fields take full width within their div */
    box-sizing: border-box; /* Prevents padding from increasing width */
}

.divider-dark {
    height: 1px;
    background-color: #202D47;
    border: #202D47;
    width: 100%;
    margin: 4rem 0;
}

.sliderSecion{
    padding: 2rem ;
    background-color: #14223D;
    color: white;
    border-radius: 20px;
}

/* Set up container styles */
.terms-text-container {
  background-color: #14223D; 
  padding-left: 20px;
  padding-right: 10px;
  padding-top: 20px ;
  padding-bottom: 20px ;
  border-radius: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 0 2rem 0px;
}

/* Customize scrollbar */
.terms-text-content {
  height: 500px; /* Set a fixed height */
  overflow-y: auto; /* Enables scrolling on the y-axis */
  padding-right: 10px;
}
.terms-text-content::-webkit-scrollbar {
    width: 10px; /* Set scrollbar width */
}

.terms-text-content::-webkit-scrollbar-track {
    background: #2C3951; /* Track color */
    border-radius: 10px;
}

.terms-text-content::-webkit-scrollbar-thumb {
    background-color: #FFD000; /* Thumb color */
    border-radius: 10px;
}

.terms-text-content::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a0; /* Change color on hover */
}

.terms-text-content h4{
  color: white;
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1px;
}

.terms-text-content p, .terms-text-content li{
  color: #818FAA;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  max-width: 770px;
  text-wrap: pretty;
  margin: 0 auto;
  margin-bottom: 20px;
}

.checkbox-container {
  display: flex;
  align-items: center;
  /* Aligns items vertically in the middle */
  gap: 4px;
  /* Space between checkbox and label */
}

.custom-checkbox {
  max-width: 35px;
  /* Set checkbox width */
  max-height: 35px;
  /* Set checkbox height */
  cursor: pointer;
  margin-top: 4px;
  /* Adjust margin to align with label */
  accent-color: #FFDE4D;
}

.checkbox-label {
  font-size: 14px;
  /* Font size for the label */
  line-height: 1.2;
  /* Tight line spacing */
  cursor: pointer;
}

.learn-more-link {
  color: #e4ba02;
  /* Blue color for the link */
  text-decoration: underline;
  /* Underline for the link */
  margin-left: 4px;
}

.learn-more-link:hover {
  color: #e9c731;
  /* Darker blue on hover */
}

@media only screen and (max-width : 1078px) {
  .account h2 {
    padding: 0 20px;
  }

  .account p {
    padding: 0 30px;
  }

  .account form {
    margin: 0 auto;
    width: 90%;
  }
}

/* For tablets (screens 768px and below) */
@media only screen and (max-width: 768px) {
  .account h2 {
    font-size: 32px;
    margin-top: 60px;
    padding: 0 15px;
  }

  .account>p {
    font-size: 14px;
    padding: 0 20px;
    max-width: 100%;
  }

  .account .stepContent {
    padding: 10px;
    margin: 0 5%;
    width: 90%;
  }

  .account form h3 {
    font-size: 20px;
  }

  .account form input,
  .account form select {
    padding: 12px;
    font-size: 14px;
  }

  .password-toggle-button .icon {
    width: 20px;
    height: 20px;
  }

  .divider-dark {
    margin: 3rem 0;
  }

  .sliderSecion,
  .terms-text-container {
    padding: 1rem;
    border-radius: 15px;
  }

  .terms-text-content {
    height: 400px;
  }

  .terms-text-content h4 {
    font-size: 18px;
  }

  .terms-text-content p, li {
    font-size: 14px;
  }

  .checkbox-container {
    gap: 3px;
  }

  .custom-checkbox {
    max-width: 30px;
    max-height: 30px;
  }

  .checkbox-label {
    font-size: 13px;
  }

  .name-container{
    display:block;
  }
}

/* For mobile devices (screens 576px and below) */
@media only screen and (max-width: 576px) {
  .account h2 {
    font-size: 26px;
    margin-top: 40px;
    padding: 0 10px;
  }

  .account>p {
    font-size: 12px;
    padding: 0 15px;
    max-width: 100%;
  }

  .account .stepContent {
    padding: 10px;
    margin: 0 5%;
    width: 90%;

  }

  .account form h3 {
    font-size: 18px;
  }

  .account form input,
  .account form select {
    padding: 10px;
    font-size: 13px;
  }

  .password-toggle-button .icon {
    width: 18px;
    height: 18px;
  }

  .name-container{
    display:block;
  }

  .divider-dark {
    margin: 2rem 0;
  }

  .sliderSecion,
  .terms-text-container {
    padding: 1rem;
    border-radius: 12px;
  }

  .terms-text-content {
    height: 300px;
  }

  .terms-text-content h4 {
    font-size: 16px;
  }

  .terms-text-content p, li {
    font-size: 12px;
  }

  .terms-text-content p {
    padding: 0;
    padding-left: 15px;
  }

  .terms-text-content ul {
    padding: 0;
    padding-left: 25px;  
  }
  
  .terms-text-content li {
    padding: 0;
  }

  .terms-text-content {
    padding-right: 5px;
  }
  .terms-text-content::-webkit-scrollbar {
      width: 6px; /* Set scrollbar width */
  }

  .terms-text-content::-webkit-scrollbar-track {
      border-radius: 6px;
  }

  .terms-text-content::-webkit-scrollbar-thumb {
      border-radius: 6px;
  }

  .custom-checkbox {
    max-width: 25px;
    max-height: 25px;
  }

  .checkbox-label {
    font-size: 12px;
  }
}
